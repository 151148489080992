import React, { useContext, useEffect } from 'react';
import AudioPlayerContext from '../../util/audioPlayerContext';
import TrackItem from './TrackItem';

export default function Comp({ songs, author }) {
  const audioCtx = useContext(AudioPlayerContext);

  const handleItemClick = (index) => {
    if (audioCtx.songIndex === index) {
      audioCtx.setPlaying(!audioCtx.playing);
    } else {
      audioCtx.setPlaying(true);
      audioCtx.setSongIndex(index);
    }
  };

  useEffect(() => {
    audioCtx.setAuthor(author);
  }, [author]);

  useEffect(() => {
    audioCtx.setSongs(songs);
    audioCtx.setSongIndex(-1);
  }, [songs]);

  return (
    <div className="not-prose border border-slate-800 rounded-lg my-10">
      <div className="container mx-auto p-6 flex-1">
        <ul className="song-list" style={{ listStyleType: 'none', padding: 0 }}>
          {songs.map((song, index) => (
            <li key={index}>
              <TrackItem
                selected={index === audioCtx.songIndex}
                title={song.trackName}
                author={audioCtx.author}
                trackNumberLabel={song.surahNo}
                onClick={() => handleItemClick(index)}
                link_url={song.downloadLink}
                trackTime={song.trackTime}
                playing={audioCtx.playing}
                progress={audioCtx.progress}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
